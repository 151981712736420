import { Navigate } from "react-router-dom";
import { useUserStateContext } from "../state/user";

export const ProtectedRoute = ({ redirectForNonAdmin, children }) => {
  const {user} = useUserStateContext();
  if (!user?.isLoggedIn) {
    return <Navigate to="/login" /> 
  }
  if (!user?.isAdmin && redirectForNonAdmin) {
    return <Navigate to={redirectForNonAdmin} />
  }
  return children;
}
