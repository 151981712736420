import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    console.log("Location Changed", location?.pathname);
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (null);
}
