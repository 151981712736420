import { Button, Col, Form, Input, Row, DatePicker, Select, Typography, Alert, Pagination, Spin, Grid } from "antd";
import { useEffect, useState } from "react";
import { AlertList } from "../components/alert_list";
import { useApiTracker } from "../hooks/use-api-traker";
import { BaseLayout } from "../layouts/base";
import backendApi from "../api/backend";
import { config } from "../conf";
import { PlacesSearch } from "../components/places_search";
import { styled } from "styled-components";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
const FilterRow = styled(Row)`
  .ant-form-item {
    margin-bottom: 6px;
  }
`;

const { Title } = Typography;
const { useBreakpoint } = Grid;
const widths = {
  threeCol: 8,
  twoCol: 12,
  oneCol: 24,
}

export function AlertsAdminPage() {
  const [form] = Form.useForm();
  const { /*xs, sm,*/ md, lg, xl, xxl } = useBreakpoint()
  const [ pageSize, setPageSize ] = useState(10);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ long, setLong ] = useState();
  const [ lat, setLat ] = useState();
  const [ query, setQuery ] = useState({ offset: 0, limit: pageSize });
  const { status, error, data, trackApi } = useApiTracker();

  const filterSpanWidth = xxl || xl || lg ? widths.threeCol : md ? widths.twoCol : widths.oneCol;
  // const filterColCount = xxl ? 3 : xl ? 3 : lg ? 3 : md ? 2 : sm ? 1 : 1;
  // const filterNearbyAndDescriptionSpan = filterColCount === 3 ? 10 : filterColCount === 2 ? 12 : 24;
  // const filterOtherSpan = filterColCount === 3 ? 7 : filterColCount === 2 ? 12 : 24;
  //
  const downloadCsv = async () => {
    const response = await backendApi.downloadAdminAlerts({ ...query, offset: undefined, limit: undefined });

    // Create a link and click it to download the file
    // Creating a link is the only way to have a named file when 
    // downloading using javascript
    let csvContent = "data:text/csv;charset=utf-8," + response.data
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "alerts_download.csv");
    document.body.appendChild(link); // Required for FF
    link.click()
  }

  useEffect(() => {
    trackApi(() => backendApi.adminAlerts(query));
  }, [query]) // Purposely don't user trackApi


  const onPageChange = async (page, size) => {
    setPageNumber(page);
    setPageSize(size);
    setQuery({ ...query, offset: (page - 1) * size, limit: size });
  }

  const onFinish = (values) => {
    console.log("onFinish", values);
    setQuery({
      ...query,
      visibility: values.alertVisibility,
      timestampStartRange: values.startDate?.$d ? Math.floor(values.startDate?.$d?.getTime() / 1000) : undefined,
      timestampEndRange: values.endDate?.$d ? Math.floor(values.endDate?.$d?.getTime() / 1000) : undefined,
      lat: lat === 0 ? undefined : lat,
      long: long === 0 ? undefined : long,
      radius: values.radius,
      category: values.category,
      alertUserRegex: values.userIdentifier,
      descriptionRegex: values.description,
      offset: 0,
      limit: pageSize,
    });
    // Reset page number when filter changes
    setPageNumber(1);
  };

  const onSortChange = (sort, order) => {
    console.log("onSortChange", sort, order);
    setQuery({ ...query, sort: JSON.stringify({ [sort]: order === 'desc' ? -1 : 1 }) });
  }

  const onAlertSave = async (id, inputValues) => {
    await backendApi.updateAlert({
      _id: id,
      ...inputValues,
    });
  }

  return (
    <BaseLayout>
      <Title>All Alerts</Title>
      <FilterRow justify={'left'}>
        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          style={{ width: '100%' }}
        >
          <Row justify={'left'} gutter={[16, 0]}>
            <Col span={filterSpanWidth}>
              <Row>
                <Col span={16}>
                  <Form.Item
                    name="place"
                  >
                    <PlacesSearch onLocationChange={({ long, lat }) => {
                      setLong(long);
                      setLat(lat);
                    }
                    } />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="radius" style={{ paddingLeft: 5 }}>
                    <Input placeholder="Distance" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={filterSpanWidth}>
              <Form.Item
                name="userIdentifier"
              >
                <Input placeholder="Reporting user (3 character limit)" />
              </Form.Item>
            </Col>
            <Col span={filterSpanWidth}>
              <Form.Item
                name="description"
              >
                <Input placeholder="Description text..." />
              </Form.Item>
            </Col>
            <Col span={filterSpanWidth}>
              <Row>
                <Col span={12}>
                  <Form.Item
                    name="startDate"
                  >
                    <DatePicker
                      placeholder="Report start date"
                      style={{ width: '100%' }}
                      changeOnBlur
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="endDate" style={{ width: '100%', paddingLeft: 5 }}>
                    <DatePicker
                      placeholder="Report end date"
                      style={{ width: '100%'}}
                      changeOnBlur
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={filterSpanWidth}>
              <Form.Item
                name="alertVisibility"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Visibility (Public/Not Public/All)"
                  options={[...config.filters.visibilityDropdown]}
                />
              </Form.Item>
            </Col>
            <Col span={filterSpanWidth}>
              <Form.Item
                name="category"
              >
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select a category (Real / Fake / Inconclusive / All)"
                  options={[...config.filters.categoryDropdown]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={9}>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={status === 'loading'}>
                  Filter
                </Button>
                <Button type="default" htmlType="submit" style={{marginLeft: 4}} onClick={() => {
                  setLat(0) ; 
                  setLong(0) ;
                  form.resetFields();
                }}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={14} style={{ textAlign: 'right' }}>
              <Button type="default" onClick={downloadCsv}><VerticalAlignBottomOutlined/> Download CSV</Button>
            </Col>
          </Row>
        </Form>
      </FilterRow>
      <Row justify={'center'}>
        <Col span={24}>
          {/* Alerts list*/}
          <AlertList onSortChange={onSortChange} alerts={data?.alerts || []} isAdmin={true} onSave={onAlertSave}/>
          {/* Show error if HTTP error occures */}
          {(error ? <Alert style={{ marginBottom: 24 }} type="error" message={"Error loading alerts. Try again later"} banner /> : '')}
        </Col>
      </Row>
      <Row justify={'center'} style={{ paddingTop: 4 }} >
        <Col span={23}>
          <Pagination defaultCurrent={1} current={pageNumber} total={data?.alertsTotal} pageSize={pageSize} onChange={onPageChange} />
        </Col>
        <Col span={1}>
          {status === 'loading' && <Spin />}
        </Col>
      </Row>
    </BaseLayout>
  )
};
