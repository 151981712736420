//import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { AutoComplete } from "antd";
import { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng} from "react-places-autocomplete";

export const PlacesSearch = ({ onLocationChange }) => {
  const [address, setAddress] = useState("");
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  useEffect(() => {
    onLocationChange({ lat, long });
  }, [lat, long])
  const handleSearch = (value) => {
    console.log("handleSearch", value);
    setLat(0);
    setLong(0);
    setAddress(value);
  };
  const onChange = (arg) => {} // Not used
  return (
    <PlacesAutocomplete onChange={onChange} value={address}>
      {({ suggestions, getInputProps }) => (
        <AutoComplete
          style={{ }}
          onSearch={(value) => {
            getInputProps().onChange({ target: { value: value } })
            handleSearch(value)
          }}
          onSelect={async (value) => {
            console.log("onSelect",value);
            const suggestion = suggestions.find(suggestion => suggestion.description === value)
            let results = await geocodeByPlaceId(suggestion.placeId)
            const latLong = await getLatLng(results[0]);
            setLat(latLong?.lat);
            setLong(latLong?.lng);
            setAddress(value);
          }}
          onBlur={async (arg) => {
            const value = arg.target.value;
            if (value && !lat && !long) {
              setAddress("");
            }
          }}
          value={address}
          defaultActiveFirstOption={true}
          placeholder="Nearby loc."
          options={suggestions.map((suggestion) => ({ label: suggestion.description, value: suggestion.description }))}
        />
      )}
    </PlacesAutocomplete>
  )
}
