import { createContext, useContext, useState } from "react";

const THEME_KEY = 'seeturtle-theme'
export const ThemeStateContext = createContext({ user: null });

export const ThemeStateProvider = ({children}) => {
  const [theme, setTheme] = useState(null);

  if (!theme && localStorage.getItem(THEME_KEY)) {
    try {
      const storedTheme = JSON.parse(localStorage.getItem(THEME_KEY))
      setTheme(storedTheme)
    } catch (e) {
      console.error("Error parsing JSON stored user", e);
    }
  }
  return (
    <ThemeStateContext.Provider value={{ theme, setTheme}}>
      {children} 
    </ThemeStateContext.Provider>
  )
}

export const useThemeStateContext = () => {
  const context = useContext(ThemeStateContext)
  return {
    theme: context.theme, 
    setTheme: (theme) => {
      // Use either local or session storage
      // Local will save the user across sessions
      // Session will save the user for the current session only
      let storage = localStorage;
      // Update staroage
      storage.setItem(THEME_KEY, JSON.stringify(theme));
      // Update context
      context.setTheme(theme)
    }
  };
}
