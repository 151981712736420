import { Alert, Col, Pagination, Row, Typography, Spin } from "antd";
import { AlertList } from "../components/alert_list";
import { useApiTracker } from "../hooks/use-api-traker";
import { BaseLayout } from "../layouts/base";
import backendApi from "../api/backend";
import { useEffect, useState } from "react";

const { Title } = Typography;

export function AlertsPage() {
  const [ pageSize, setPageSize ] = useState(10);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ sort/* , setSort */ ] = useState(JSON.stringify({ alertTimestamp: -1 }));
  const { status, error, data, trackApi } = useApiTracker();

  useEffect(() => {
    console.log("useEffect pageNumber", pageNumber);
    trackApi(() => backendApi.myAlerts({ offset: (pageNumber - 1) * pageSize, limit: pageSize, sort }));
  }, [ pageNumber, pageSize, sort ]);

  const onPageChange = async (page, size) => {
    console.log("onPageChange", page, size);
    setPageNumber(page);
    setPageSize(size);
  }

  return (
    <BaseLayout>
      <Row justify={'center'}>
        <Col span={24}>
          <Title>My Alerts</Title>
          {/* Alerts list*/}
          <AlertList alerts={data?.alerts || []} isAdmin={false} />
          {/* Show error if HTTP error occures */}
          {(error ? <Alert style={{ marginBottom: 24 }} type="error" message={"Error loading alerts. Try again later"} banner /> : '')}
        </Col>
      </Row>
      <Row justify={'center'}  style={{paddingTop: 4}} >
        <Col span={23}>
          <Pagination defaultCurrent={ 1 } current={ pageNumber } total={ data?.alertsTotal } pageSize={ pageSize } onChange={ onPageChange } />
        </Col>
        <Col span={1}>
          { status === 'loading' && <Spin/> }
        </Col>
      </Row>
    </BaseLayout>
  )
};
