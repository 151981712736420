import { Col, Layout, Typography, Row, Switch, theme, Grid } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Link from "antd/es/typography/Link";
import { useThemeStateContext } from "../state/theme";
import { useUserStateContext } from "../state/user";
import backendApi from "../api/backend";

const { useToken } = theme;
const { Text } = Typography;

const { useBreakpoint } = Grid;

export const BaseLayout = ({ children }) => {
  const { /* xs , sm, */  md, lg, xl, xxl } = useBreakpoint()
  const { user, setUser } = useUserStateContext();
  const { theme, setTheme } = useThemeStateContext();
  const { token } = useToken();

  const isSmall = !xxl && !xl && !lg && !md;
  const padding = isSmall ? '10px' : '50px';
  // Logout Handler
  const logoutHandler = async () => {
    backendApi.deviceExpire();
    setUser({});
    backendApi.setApiKey(null);
  }

  return (
    <Layout style={{
      minHeight: '100%', 
      height: '100%'
    }}>
      <Header style={{ background: token?.colorBgHeader, paddingLeft: padding, paddingRight: padding }}>
        <Row className="site-header-content">
          <Col span={isSmall ? 8 : 11}>
            <img src="/logo.png" alt="See Turtles" height="50px"/>
          </Col>
          <Col span={2} style={{textAlign: 'center'}}>
              <Switch 
                checkedChildren={"Dark"}
                unCheckedChildren={"Light"}
                checked={!!theme?.isDark} 
                onChange={(checked) => setTheme({ ...theme, isDark: checked ? true : false } )}
              /> 
          </Col>
          {
            user?.isLoggedIn && 
            (
              <Col span={isSmall ? 14 : 11} style={{ textAlign: 'right', lineHeight: 0 }}>
                 <div style={{overflow: 'hidden', paddingTop: 10}}>
                  <Text style={{color: 'grey'}}>Logged in as {user?.userIdentifier}</Text><br/>
                  <Link type="link" onClick={logoutHandler} style={{marginTop: 0, marginBottom: 0, padding: 0}}>Logout</Link>
                </div>
              </Col>
            )
          }
        </Row>
      </Header>
      <Content
        style={{
          width: '100%',
        }}
        justify="center"
      >
        <div
          className="site-layout-content"
          style={{
            minHeight: '100%',
            padding: padding,
          }}
        >
          {children}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        See Turtles ©2023
      </Footer>
    </Layout>
  );
};
