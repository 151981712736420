import { createContext, useContext, useState } from "react";
import backendApi from "../api/backend";

export const UserStateContext = createContext({ user: null });

const USER_KEY = 'seeturtle-user'

// Set api Key at bootup
if ((localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY))) {
  try {
    const storedUser = JSON.parse(localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY))
    if (storedUser?.apiKey) {
      backendApi.setApiKey(storedUser.apiKey);
    }
  } catch (e) { 
    // Ignore error because it doesn't matter if that happens here
  }
}

export const clearStateAndGoToRoot = () => {
    // Clear State
    localStorage.removeItem(USER_KEY);
    sessionStorage.removeItem(USER_KEY);
    // Navigate to root
    window.location.href = '/';
}

export const UserStateProvider = ({children}) => {
  const [user, setUser] = useState(null);
  if (!user && (localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY))) {
    try {
      const storedUser = JSON.parse(localStorage.getItem(USER_KEY) || sessionStorage.getItem(USER_KEY))
      setUser(storedUser)
      if (storedUser?.apiKey) {
        backendApi.setApiKey(storedUser.apiKey);
        backendApi.userInfo().then((response) => {
          console.log("User is admin", response.data.isAdmin);
          let newUser = { ...storedUser, isAdmin: response.data.isAdmin };
          let storage;
          if (storedUser.remember) {
            storage = localStorage;
          } else {
            storage = sessionStorage;
          }
          // Update staroage
          storage.setItem(USER_KEY, JSON.stringify(newUser));
          setUser({ ...storedUser, isAdmin: response.data.isAdmin });
        }).catch((error) => {
          console.error("Error checking if user is admin", error);
        });
      }
    } catch (e) {
      console.error("Error parsing JSON stored user", e);
    }
  }
  return (
    <UserStateContext.Provider value={{ user, setUser}}>
      {children} 
    </UserStateContext.Provider>
  )
}

export const useUserStateContext = () => {
  const context = useContext(UserStateContext)
  // if user lastLogin is more then 2 weeks ago, then clear user
  if (context.user && context.user.lastLogin && (Date.now() - context.user.lastLogin) > 1000 * 60 * 60 * 24 * 14) {
    context.setUser(null);
  }
  return {
    user: context.user, 
    setUser: (user) => {
      // Use either local or session storage
      // Local will save the user across sessions
      // Session will save the user for the current session only
      let storage;
      if (context.user?.remember !== undefined ? context.user?.remember : user?.remember) {
        storage = localStorage;
      } else {
        storage = sessionStorage;
      }
      // Update backend api key
      backendApi.setApiKey(user?.apiKey);
      // Update staroage
      storage.setItem(USER_KEY, JSON.stringify(user));
      // Update context
      context.setUser(user)
    }
  };
}
