import { Button, Form, Input, Alert, Card, Row, Col, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { BaseLayout } from "../layouts/base";
import { useUserStateContext } from "../state/user";
import { useApiTracker } from "../hooks/use-api-traker";
import backendApi from "../api/backend";
const { Text, Link, Title } = Typography;

export function VerificationPage() {
  const { user, setUser } = useUserStateContext();
  const { verificationSessionId } = useParams();
  const { status, error, trackApi } = useApiTracker();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const loginResponse = await trackApi(() => backendApi.loginVerify(verificationSessionId, values.code));

    if (loginResponse.status>=400) {
      return;
    }
    backendApi.setApiKey(loginResponse.data.apiKey);
    let isAdmin = loginResponse.data.isAdmin;
    // Once this data is set, we can set the user state
    setUser({ ...user, ...loginResponse.data, isAdmin, isLoggedIn: true, lastLogin: Date.now() });
    if (isAdmin) {
      return navigate('/alerts-admin');
    }
    return navigate('/alerts');
  };
  
  const onResendClick = async () => {
    const response = await trackApi(async () => {
      const response = await new Promise((resolve, reject) => {
        // Check if grepcaptcha is loaded
        if (!window.grecaptcha.enterprise) {
          resolve({ status: 400, message: 'reCAPTCHA not loaded' });
          return;
        }
        // Check if the reCAPTCHA token is available
        if (!window.grecaptcha.enterprise.execute) {
          resolve({ status: 400, message: 'reCAPTCHA token not available' });
          return;
        }
        window.grecaptcha.enterprise.ready(async () => {
          try {
            const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SECRET, {action: 'device_verify_start'});
            // Do whatever here to add the token to wherever you need and send it to the device-verify-start as the property "captchaToken".
            const response = await trackApi(() => backendApi.loginStart(user.userIdentifier, token));
            resolve(response);
          } catch (error) {
            reject(error);
          }
        });
      });
      return response;
    });
    if (response.status>=400) {
      return;
    }
    return navigate(`/verify/${response.data.verificationSessionId}`);
  };

  return (
    <BaseLayout>
      <Row justify="center">
        <Col span={24}>
          <Title style={{ textAlign: 'center' }}>Login to get a list of your alerts</Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Card style={{ width: '384px', margin: 'auto', borderRadius: 40 }}>
            {(error ? <Alert style={{ marginBottom: 24 }} type="error" message={error?.error || "Unable to verify"} banner /> : '')}
            <Text>Enter the code from the text message you received</Text>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              layout={'vertical'}
              autoComplete="off"
              style={{ marginTop: 24 }}
            >
              <Form.Item
                label="Verification Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the verification code received in a text message',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Link type="link" onClick={onResendClick}>Resend Verification Code</Link>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit" disabled={status === 'loading'}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </BaseLayout>
  )
};
