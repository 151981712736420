import { useEffect, useReducer, useCallback } from "react";

function log(...args) {
  // console.log("useApiTracker", ...args);
}

// Keep state logic separated
const reducer = (state, action) => {
  log("fetchReducer-starting");
  let ret;
  switch (action.type) {
    case 'start':
      ret = { ...state, status: 'loading' };
      log("fetchReducer", action, ret);
      break;
    case 'end':
      ret = { ...state, error: undefined, data: action.payload, status: 'done' };
      log("fetchReducer", action, ret);
      break;
    case 'error':
      ret = { ...state, data: undefined, error: action.payload.error, errorStatus: action.payload.errorStatus, status: 'error' };
      log("fetchReducer", action, ret);
      break;
    default:
      ret = state;
      log("fetchReducer-default", action, ret);
  }
  log("fetchReducer-ending");
  return ret;
}

export function useApiTracker({ trackNow } = {}) {
  const initialState = {
    status: 'init',
    error: undefined,
    data: undefined,
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const trackApi = useCallback(async (makeApiCallCb) => {
    dispatch({ type: 'start' });
    let response;
    try {
      response = await makeApiCallCb();
      // Axios thorws an error for non 200 status codes
      dispatch({ type: 'end', payload: response.data });
    } catch (error) {
      response = error.response;
      dispatch({ type: 'error', payload: { error: error?.response?.data || error.message, errorStatus: error?.response?.status || 0 } });
    }
    return response;
  }, [ dispatch ]);

  // Use useEffect to run trackNow function at start
  useEffect(() => {
    if (trackNow && state.status === 'init') {
      log("useEffect trackNow");
      trackApi(() => trackNow());
    }
  }, []);


  return {
    ...state,
    trackApi
  }
}
