import { Navigate } from "react-router-dom";
import { useUserStateContext } from "../state/user";

export const HomePageForwarder = ({ children }) => {
  const {user} = useUserStateContext();
  if (!user?.isLoggedIn) {
    return <Navigate to="/login" /> 
  } else if (user.isAdmin) {
    return <Navigate to="/alerts-admin" /> 
  } else if (!user.isAdmin) {
    return <Navigate to="/alerts" /> 
  }
  return children;
}
