import { Button, Checkbox, Form, Input, Alert, Card, Row, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { BaseLayout } from "../layouts/base";
import { useUserStateContext } from "../state/user";
import { useApiTracker } from "../hooks/use-api-traker";
import backendApi from "../api/backend";

const { Text, Title } = Typography;

export function LoginPage() {
  const { setUser } = useUserStateContext();
  const { status, error, trackApi } = useApiTracker();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setUser({ remember: values.remember, userIdentifier: values.phone });
    const response = await trackApi(async () => {
      const response = await new Promise((resolve, reject) => {
        // Check if grepcaptcha is loaded
        if (!window.grecaptcha.enterprise) {
          resolve({ status: 400, message: 'reCAPTCHA not loaded' });
          return;
        }
        // Check if the reCAPTCHA token is available
        if (!window.grecaptcha.enterprise.execute) {
          resolve({ status: 400, message: 'reCAPTCHA token not available' });
          return;
        }
        window.grecaptcha.enterprise.ready(async () => {
          try {
            const token = await window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SECRET, {action: 'device_verify_start'});
            // Do whatever here to add the token to wherever you need and send it to the device-verify-start as the property "captchaToken".
            const response = await trackApi(() => backendApi.loginStart(values.phone, token));
            resolve(response);
          } catch (error) {
            reject(error);
          }
        });
      });
      return response;
    });
    if (response.status>=400) {
      return;
    }
    return navigate(`/verify/${response.data.verificationSessionId}`);
  };

  return (
    <BaseLayout>
      <Row justify="center">
        <Col span={24}>
          <Title style={{ textAlign: 'center' }}>Login to get a list of your alerts</Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <Card style={{ width: '384px', margin: 'auto', borderRadius: 40 }}>
            {(error ? <Alert style={{ marginBottom: 24 }} type="error" message={error?.message || error} banner /> : '')}
            <Text>Enter phone number to login</Text>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              layout={'vertical'}
              autoComplete="off"
              style={{ marginTop: 24 }}
            >
              <Form.Item
                label="Phone Number (including country code)"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number to verify your account!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>
                  <Text style={{ fontSize: 12 }}>
                    This is my personal device
                  </Text>
                  <Text style={{ fontSize: 10 }}>
                    <br />
                    (This will keep you logged in for 2 weeks)
                  </Text>
                </Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit" disabled={status === 'loading'}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </BaseLayout>
  )
};
